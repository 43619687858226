import React from 'react';
import {useState} from 'react';
import {Card, Button, Form, Alert, Accordion} from 'react-bootstrap';

export default function Orderform(){
    const [order, setOrder] = useState({});
    const [updater, setUpdater] = useState(false);
    const [validated, setValidated] = useState(false);

    const handleChange = (e) => {
        let changer = order;
        changer[e.target.name] = e.target.value;
        setOrder(changer);
        setUpdater(!updater);
        console.log(order[e.target.name]);
    }

    const handleVerify = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            handleSubmit(event);
        }
      
          setValidated(true);
    }

    const handleSubmit = () => {
        
        console.log(order);

        const pkg = order;

        fetch('/ordersubmit', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
            },
            body: JSON.stringify(pkg)
        })
        .then(window.open("https://nvcorporateservices.com/welcome"));
    }

    return(
        <>
        <div className="container orderform">
            <Form noValidate validated={validated} onSubmit={handleVerify} >
            <div className="row">
                <div className="col-md-6 col-sm-12 personalbox">
                    <h3>About You</h3>
                    <Form.Group controlId="orderform">
                        <Form.Control required type="text" placeholder="Full Name" name="fullname" onChange={handleChange} />
                        <Form.Control required type="email" placeholder="Email Address (youremail@provider.com)" name="email" onChange={handleChange} />
                        <Form.Control required type="text" placeholder="Phone Number" name="phone" onChange={handleChange} />
                        <Form.Control.Feedback type="invalid">Fix Errors Here</Form.Control.Feedback>
                    </Form.Group>
                </div>
                <div className="col-md-6 col-sm-12 bizbox">
                    <h3>About Your Business</h3>
                    <Form.Group controlId="orderform">
                        <Form.Control required type="text" placeholder="Company Name" name="bizname" onChange={handleChange} />
                        <Form.Control required type="text" placeholder="Current URL/Website" name="bizurl" onChange={handleChange} />
                        <Form.Control required as="select" name="biztype" onChange={handleChange}>
                            <option>Select One...</option>
                            <option>Home Business</option>
                            <option>Real Estate</option>
                            <option>Retail</option>
                            <option value="Other">Other (please specify)</option>
                        </Form.Control>
                        {order.biztype === 'Other' &&
                        <Form.Control required type="text" placeholder="Type of Business" name="bizother" onChange={handleChange} />
                        }
                    </Form.Group>
                </div>
                <div className="col-6 offset-3 infobox">
                    <h3>Other Information</h3>
                    <Form.Group controlId="orderform">
                        <Form.Control required type="text" placeholder="Quoted Price" name="qprice" onChange={handleChange} />
                        <Form.Control as="textarea" placeholder="Client Notes" name="cnotes" onChange={handleChange} />
                    </Form.Group>
                </div>
            </div>
            {typeof order.fullname == "string" && typeof order.email == "string" && typeof order.phone == "string" &&
            <div className="row">
                <div className="col-md-6 col-sm-12 paybox">
                    <h3>Payment Info</h3>
                    <Form.Group controlId="orderform">
                        <Form.Control required as="select" name="paytype" onChange={handleChange}>
                            <option>Select a Payment Method</option>
                            <option>Transfer</option>
                            <option>Visa</option>
                            <option>Mastercard</option>
                            <option>Discover</option>
                            <option>American Express</option>
                            <option>Check</option>
                        </Form.Control>
                    </Form.Group>
                    {(order.paytype === 'Visa' || order.paytype === 'Mastercard' || order.paytype === 'Discover') &&
                    <>
                    <div className="row">
                        <div className="col-12">
                            <Form.Group controlId="orderform">
                                <Form.Control required type="text" placeholder="Name on Card" name="cardname" onChange={handleChange} />
                                <Form.Control required type="text" placeholder="Card Number" name="cardnum" onChange={handleChange} />
                                {order.cardnum !== undefined && order.cardnum.length !== 16 && validated &&
                                <Form.Text id="cardnumcheck" muted>Check Your Card Number</Form.Text>
                                }
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <Form.Control required type="text" placeholder="Expiration Date" name="expire" onChange={handleChange} />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Form.Control required type="text" placeholder="Security Number" name="cvv" onChange={handleChange} />
                        </div>
                    </div>
                    </>
                    }
                    {order.paytype === 'American Express' &&
                    <>
                    <div className="row">
                        <div className="col-12">
                            <Form.Group controlId="orderform">
                                <Form.Control required type="text" placeholder="Name on Card" name="cardname" onChange={handleChange} />
                                <Form.Control required type="text" placeholder="Card Number" name="cardnum" onChange={handleChange} />
                                {order.cardnum !== undefined && order.cardnum.length !== 15 && validated &&
                                <Form.Text id="cardnumcheck" muted>Check Your Card Number</Form.Text>
                                }
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <Form.Control required type="text" placeholder="Expiration Date" name="expire" onChange={handleChange} />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Form.Control required type="text" placeholder="Security Number" name="cvv" onChange={handleChange} />
                        </div>
                    </div>
                    </>
                    }
                    {order.paytype === 'Check' &&
                    <>
                    <div className="row">
                        <div className="col-12">
                            <Form.Group controlId="orderform">
                                <Form.Control required type="text" placeholder="Name on Check" name="checkname" onChange={handleChange} />
                                <Form.Control required type="text" placeholder="Name of Your Bank" name="bankname" onChange={handleChange} />
                                <Form.Control required type="text" placeholder="Check Number" name="checknum" onChange={handleChange} />
                            </Form.Group>
                        </div>
                    </div>
                    </>
                    }
                </div>
                <div className="col-md-6 col-sm-12 billbox">
                    <h3>Billing Address</h3>
                    <div className="row">
                        <div className="col-12">
                            <Form.Group controlId="orderform">
                                <Form.Control required type="text" placeholder="Street Address" name="staddress" onChange={handleChange} />
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <Form.Group controlId="orderform">
                                <Form.Control required type="text" placeholder="Bldg/Apt # (n/a if none)" name="stnumber" onChange={handleChange} />
                                <Form.Control required type="text" placeholder="State" name="state" onChange={handleChange} />
                            </Form.Group>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Form.Group controlId="orderform">
                                <Form.Control required type="text" placeholder="City" name="city" onChange={handleChange} />
                                <Form.Control required id="zipbox" type="text" placeholder="Zip Code" name="zipcode" onChange={handleChange} />
                            </Form.Group>
                        </div>
                    </div>
                </div>
            </div> 
            }
            {typeof order.zipcode == "string" &&
            <>
            <Accordion flush>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        Terms and Conditions
                    </Accordion.Header>
                    <Accordion.Body style={{"textAlign":"justify"}}>
                        <p>These Terms and Conditions ("Terms") are entered into by and between Nevada Corporate Services ("NCS" or "we," "us," or "our") and the client ("Client," "you," or "your") for the provision of corporate structure, asset protection, and tax savings services. By engaging NCS's services, you agree to be bound by these Terms. Please read these Terms carefully before utilizing our services.</p>
                        <br />
                        <h3>Scope of Services:</h3>
                        <p>NCS offers a wide array of services, including but not limited to tax strategy and preparation, corporate structuring and filing, and bookkeeping services. We provide strategy sessions and consultations to assist clients in achieving their financial and business objectives. It is important to note that while we assist in forming and filing corporate structures, we do not guarantee any economic outcomes resulting from the use or activity of said structures. Our services are designed to facilitate establishing and maintaining corporate entities and offer advice on strategies to enhance financial efficiency.</p>
                        <br />
                        <h3>Guarantees:</h3>
                        <p><strong>Filing and Setup Guarantee:</strong> NCS guarantees to file all necessary paperwork required for corporate structure and filing with the Secretary of State. We will ensure the proper documentation is submitted in a timely and accurate manner. We commit to completing the initial setup of your corporate entity, including securing the entity's name and filing articles of incorporation or organization.</p>
                        <p><strong>No Financial Guarantees:</strong> While we strive to provide advice and services to optimize tax savings and asset protection, we do not guarantee to generate specific financial results, such as tax savings or asset protection. The actual financial outcomes depend on various factors, including legal compliance, individual circumstances, and the specific activities of the corporate entity.</p>
                        <br />
                        <h3>Client Responsibilities:</h3>
                        <p>Clients are responsible for providing accurate and complete information to NCS to facilitate the provision of services. It is essential to cooperate promptly with requests for documentation and information. Clients are also responsible for ensuring that the use and activities of the corporate entity comply with all relevant laws, regulations, and requirements.</p>
                        <br />
                        <h3>Resident Agent Services:</h3>
                        <p>NCS may act as the resident agent for your corporate entity if needed. This service includes receiving legal notices, government correspondence, and service of process on your behalf. Please note that this service is separate from the corporate structure and filing services and may be subject to additional fees.</p>
                        <br />
                        <h3>Liability and Indemnification:</h3>
                        <p>NCS is not liable for any use or activity conducted through the corporate structures we help establish. Clients shall indemnify, defend, and hold NCS harmless from any claims, losses, damages, or liabilities arising from using the corporate structures. NCS is not responsible for the legal or financial consequences of the client's business operations.</p>
                        <br />
                        <h3>Fee Structure:</h3>
                        <p>Clients are responsible for paying the fees agreed upon in the service agreement. Additional fees may apply for services beyond the initial setup and filings. All fees are non-refundable once services have been initiated.</p>
                        <br />
                        <h3>Termination of Services:</h3>
                        <p>NCS reserves the right to terminate its services if a client fails to fulfill their obligations or engages in unlawful activities. In such cases, no refunds of fees will be provided.</p>
                        <br />
                        <h3>Privacy and Data Protection:</h3>
                        <p>NCS respects the privacy of client information and complies with relevant data protection laws. Information provided to NCS will be used solely to provide services and will not be shared with third parties except as required by law.</p>
                        <br />
                        <h3>Governing Law:</h3>
                        <p>The laws of the state of Nevada govern these Terms and Conditions.</p>
                        <br />
                        <h3>Modification of Terms:</h3>
                        <p>NCS reserves the right to modify these Terms and Conditions at its discretion. Clients will be notified of any changes to the Terms.</p>
                        <br />
                        <p><strong>By engaging the services of Nevada Corporate Services, you acknowledge and accept these Terms and Conditions. If you have any questions or concerns, please contact us for clarification before proceeding with our services.</strong></p>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Form.Group controlId="formsign" style={{"textAlign":"center"}}>
                <Form.Check type="checkbox" value="accept" name="accept" onChange={handleChange} label="I have read and agree to the above Terms and Conditions." />
                <Form.Label style={{"marginTop":"10px"}}>Signature</Form.Label>
                <Form.Control className="w-50" type="text" placeholder="Your Full Name" name="signature" onChange={handleChange} style={{"margin":"auto auto 10px"}} />
            </Form.Group>
            {typeof order.signature == "string" && typeof order.accept == "string" &&
                <Button type="button" block className="w-50 submission" onClick={handleSubmit}>Submit</Button> 
            }
            </>
            }
            </Form>
        </div>
        </>
    )
}