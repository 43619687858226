import {ListGroup} from 'react-bootstrap';
import logo from '../images/logo.png';

export default function Foot(){
  return(
    <>
    <div className="container-fluid shoe">
      <div className="container">
        <div className="row">
          <div className="col-6 externals" style={{"textAlign":"center"}}>
            <img src={logo} alt="NV Corporate Logo" width="50%" style={{"margin":"auto"}} />
          </div>
          <div className="col-6 copy" style={{"marginTop":"30px"}}>
              <p>&copy; 2020 Nevada Corporate Services</p>
              <p>Your one-stop shop for incorporating in the great state of Nevada!</p>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
