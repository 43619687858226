import React from 'react';
import {useState} from 'react';
import {Card, Button, Form, Alert, Accordion} from 'react-bootstrap';

export default function FramePage(){
    
    return(
        <>
        <div className="container framepage">
            <iframe src="https://esos.nv.gov/EntitySearch/OnlineEntitySearch" title="Online Entity Search" width="100%" height="1200px" />
        </div>
        </>
    )
}