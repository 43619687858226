import {Nav, Navbar, NavDropdown} from 'react-bootstrap';
import logo from '../images/logo.png';

export default function Navi(){
  return(
    <>
    <div className="container-fluid navi">
      <div className="row">
        <Navbar expand="md" className="container">
          <Navbar.Brand href="/"><img src={logo} alt="Generate Buyers" /></Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Nav className="justify-content-end">
              <Nav.Link href="/">Home</Nav.Link>
              <NavDropdown title="Services" id="ServDrop">
                <NavDropdown.Item href="/entities">Corporate Entities</NavDropdown.Item>
                <NavDropdown.Item href="/services">Business Services</NavDropdown.Item>
                <NavDropdown.Item href="/legal">Legal Services</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="https://esos.nv.gov/EntitySearch/OnlineEntitySearch">Entity Name Availablity</Nav.Link>
              <Nav.Link href="/states">Compare Your State</Nav.Link>
              <Nav.Link href="/faq">FAQ</Nav.Link>
              <NavDropdown title="About Us" id="BoutDrop">
                <NavDropdown.Item href="/commitment">Our Commitment</NavDropdown.Item>
                <NavDropdown.Item href="/experts">Our Experts</NavDropdown.Item>
                <NavDropdown.Item href="/contact">Contact Us</NavDropdown.Item>
                <NavDropdown.Item href="/privacy">Privacy Statement</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/order">Order</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
    </>
  )
}
